import React from 'react';
import PropTypes from 'prop-types';

const Footer = (props) => {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2>Other Information</h2>
                    </div>
                    <div className='col-md-12'> <h2 style={{marginBottom: '10px'}}>Parking</h2>
                        <p className={'textCenter'}>If you are staying at the hotel there is parking provided, however there are no spaces for visitors. There are multiple car parks within a few minute walk that offer 12 hour parking for &#xa3;4.</p>
                    </div>
                    <div className='col-md-6'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2032.6752643912455!2d-1.769171068715422!3d50.73392367316896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48739ea12aae5437%3A0x1ef157ff578faeaf!2sLeisure%20Centre%20Car%20Park%20(west)%20Christ%20Church!5e1!3m2!1sen!2suk!4v1688245318127!5m2!1sen!2suk"
                            width="100%" height="250" style={{border:0}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1545.3717711096442!2d-1.7676139975611225!3d50.733496268024616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48739ea751f2da25%3A0xa1751b8e02b79a96!2sLeisure%20Centre%20Car%20Park%20East!5e1!3m2!1sen!2suk!4v1688245420398!5m2!1sen!2suk"
                            width="100%" height="250" style={{border:0}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                    <div className='col-md-6'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2866.295013262673!2d-1.7717290350832575!3d50.73145004910678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48739ea30389e68d%3A0x9d9faf1e47c2e566!2sChristchurch%20Priory%20Car%20Park!5e1!3m2!1sen!2suk!4v1688245503250!5m2!1sen!2suk"
                            width="100%" height="250" style={{border:0}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6437.463966821376!2d-1.775824959351719!3d50.73102286994089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48739ebd1c40afa9%3A0xbe103a4e29275c80!2sMayors%20Mead%20Car%20Park!5e1!3m2!1sen!2suk!4v1688245701794!5m2!1sen!2suk"
                            width="100%" height="250" style={{border:0}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className='col-md-6'>
                        <h2 className={'marginTop'}>Nearest Train station</h2>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d4102.0410676326555!2d-1.7780594435485284!3d50.73658788995148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x48739eb9ff18b3ef%3A0xa84634546ce10520!2sChristchurch%2C%20Stour%20Road%2C%20Christchurch!3m2!1d50.7382802!2d-1.7845061999999998!4m5!1s0x48739ea47ad2d6bd%3A0xb01279d5db8fdf64!2s18%20Castle%20Street%2C%20Christchurch!3m2!1d50.7340046!2d-1.7746418!5e0!3m2!1sen!2suk!4v1688244605841!5m2!1sen!2suk"
                            width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className='col-md-6'>
                        <h2 className={'marginTop'}>Places to stay</h2>
                        <a className={'firstLink'} href={"https://www.harbourhotels.co.uk/kings-arms"} target={"_blank"}>The Kings Arms</a>
                        <a href={"https://www.harbourhotels.co.uk/christchurch"} target={"_blank"}>Harbour Hotel Christchurch</a>
                        <a href={"https://www.travelodge.co.uk/hotels/586/Christchurch-hotel"} target={"_blank"}>Travelodge Christchurch</a>
                    </div>
                </div>
            </div>
        </div>
    )
};

Footer.propTypes = {};

export default Footer