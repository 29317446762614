import React, {Component} from 'react';
import TimeLinePiece from "./timeLinePeice";
import Fade from 'react-reveal/Fade';

class WorkExperience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            experience: [
                {
                    company: 'Ceremony',
                    description: '2pm - 3pm',
                    role: 'Pavilion',
                    technologies: "On the field adjacent the hotel in the white hut"
                },
                {
                    company: 'Photos and drinks',
                    description: '3pm - 4pm',
                    role: 'Field and ruins',
                    technologies: "Free to roam"
                },
                {
                    company: 'Wedding breakfast',
                    description: '4pm',
                    role: 'Main hall',
                    technologies: "In the building next to the Hotel, top floor"
                },
                {
                    company: 'Reception',
                    description: '5pm - 12am',
                    role: 'Main hall',
                    technologies: "In the building next to the Hotel, both floors"
                },
            ]
        }
    }

    renderTimeLineParts() {
      return this.state.experience.map((work, index) => {
          return <Fade bottom key={index}><TimeLinePiece work={work} /> </Fade>;
      })
    }

    render() {
        return <div className='workExperience'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Fade bottom>
                        <h2>Rough Schedule </h2>
                        </Fade>
                    </div>
                </div>
                {this.renderTimeLineParts()}
            </div>
        </div>
    }
}

export default WorkExperience;