import { useState, useMemo } from 'react';

// Using this with Select, you need the value you set to be the full {input: string, value: string}
export const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState('');

    const returnValue = () => {
        if (!value || (typeof value) === 'string') {
            return value;
        }
        // required for react-select
        if(Array.isArray(value) && value.value) {
           return value.map((type) => type.value)
        }
        // for checkbox
        if(Array.isArray(value)) {
            return value;
        }

        return value.value;
    };

    const bind = useMemo(() => {
        return {
            value,
            error,
            onChange: event => {
                if (event.target) {
                    setValue(event.target.value);
                    return;
                }
                setValue(event);
            },
            setValue
        }
    }, [value, initialValue, error])

    return {
        value: returnValue(),
        setValue,
        setError,
        error,
        reset: () => {
            setValue('');
        },
        clearError: () => {
            setError('');
        },
        bind
    };
};