import React, {Fragment, useEffect, useState} from 'react';
import Modal from "../modal/modal";
import Input from "../formElements/input";
import {useInput} from "../../hooks/useInput";
import RadioButton from "../formElements/radioButton";
import config from '../../../config'
import axios from 'axios'

const AttendeeForm = ({firstName, lastName, id, index, saveAttendeeData, removeAttendee, hasInitialAttendees}) => {
    const firstNameInput = useInput('');
    const lastNameInput = useInput('');
    const attendingInput = useInput('');

    useEffect(() => {
        //set initial values
        firstNameInput.setValue(firstName);
        lastNameInput.setValue(lastName);
    }, [])

    useEffect(() => {
        saveAttendeeData({
            id,
            firstName: firstNameInput.value,
            lastName: lastNameInput.value,
            attending: attendingInput.value
        })
    }, [firstNameInput.value, lastNameInput.value, attendingInput.value])

    return <div className={'attendeeData'}>
        <p>Attendee {index + 1}</p>
        {!hasInitialAttendees && <span className={'removeAttendeeButton'} onClick={() => removeAttendee(id)}>X</span>}
        <Input bind={firstNameInput.bind} label={'First Name'}/>
        <Input bind={lastNameInput.bind} label={'Second Name'}/>
        <RadioButton bind={attendingInput.bind} options={[{label: 'Yes', value: 'YES'}, {label: 'No', value: 'NO'}]}
                     label={'Will you be attending?'}/>
    </div>
}

const Rsvp = ({showModal, closeModal, location}) => {

    const [attendees, setAttendees] = useState([])
    const [secret, setSecret] = useState('')
    const songInput = useInput('');
    const gameInput = useInput('');
    const emailInput = useInput('');
    const secretInput = useInput('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [hasInitialAttendees, setHasInitialAttendees] = useState(false);


    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);

        const attendeeParams = params.get('attendees')
        const key = params.get('key')
        if (key) {
            setSecret(key)
        }

        if (attendeeParams) {
            setHasInitialAttendees(true);
            const attendeeData = [];
            // Split by the comma to get each person
            const people = attendeeParams.split(',');
            people.forEach((person, index) => {
                // then the first and last are seperated by a -
                const firstLast = person.split('-');
                attendeeData.push({
                    firstName: firstLast[0],
                    lastName: firstLast[1],
                    id: `${Date.now()}${index}`
                })
            })
            setAttendees(attendeeData);
        } else {
            //Otherwise add one blank attendee
            setAttendees([{
                firstName: '',
                lastName: '',
                id: Date.now()
            }]);
        }
    }, [])

    if (!showModal) {
        return null;
    }

    const saveAttendeeData = ({id, firstName, lastName, attending}) => {
        const newAttendeeData = attendees.map((attendee) => {
            // Keep all other records the same, but update the one that has changed.
            if (attendee.id !== id) {
                return attendee
            }
            return {
                id,
                firstName,
                lastName,
                attending
            }
        })

        setAttendees(newAttendeeData);
    }

    const onSubmit = () => {
        const attendeeData = attendees.map((attendee) => {
            return {
                FIRST_NAME: attendee.firstName,
                LAST_NAME: attendee.lastName,
                ATTENDING: attendee.attending === 'YES'
            }
        })

        const objectToSend = {
            "ATTENDEES": attendeeData,
            "SONG": songInput.value,
            "GAME": gameInput.value,
            "EMAIL": emailInput.value,
            "SECRET": secret ? secret : secretInput.value,
        }

        setError(false);

        axios.post(`${config.API_GATEWAY_URL}/rsvp`, objectToSend).then(() => {
            setSuccess(true)
        }).catch(() => {
            setError(true);
        })
    }

    const addAttendee = () => {
        const attendeeData = [...attendees];
        attendeeData.push({
            firstName: '',
            lastName: '',
            id: Date.now()
        })
        setAttendees(attendeeData);
    }

    const removeAttendee = (id) => {
        const attendeeData = attendees.filter((attendee) => {
            return attendee.id !== id;
        });
        setAttendees(attendeeData);
    }

    const renderAttendeeForms = () => {
        return attendees.map((attendee, index) => {
            return <AttendeeForm
                firstName={attendee.firstName}
                lastName={attendee.lastName}
                key={attendee.id}
                id={attendee.id}
                index={index}
                saveAttendeeData={saveAttendeeData}
                removeAttendee={removeAttendee}
                hasInitialAttendees={hasInitialAttendees}
            />
        })
    }

    return <Modal close={closeModal}>
        {!success && <Fragment>
            <h2>RSVP</h2>
            {renderAttendeeForms()}

            {!hasInitialAttendees && <Fragment>
                <p className={'mutedText errorText'}>If you use the link you were sent the attendee's will be auto filled with your names, Otherwise you can add them manually here.</p>
                <button className={'rsvpPageButton buttonPaddingRsvp'} onClick={addAttendee}>Add another attendee</button>
            </Fragment>}

            <p className={'mutedText'}>We currently do not have the food selection, we will send you the options when we
                know via email</p>
            <Input bind={emailInput.bind} label={'Email'}/>
            <Input bind={songInput.bind} label={'Song to get you Dancing'}/>
            <Input bind={gameInput.bind} label={'Favourite board game'}/>
            {!secret && <Input bind={secretInput.bind} label={'Password'}/>}

            {error && <p className={'errorText'}>Something has gone wrong submitting, please try again</p>}
            <button className={'rsvpPageButton'} onClick={onSubmit}>Submit</button>
        </Fragment>}
        {success && <div>
            <p>Thank you! You have successfully submitted your RSVP. We look forward to seeing you soon!</p>
            <button className={'rsvpPageButton'} onClick={closeModal}>Close</button>
        </div>}
    </Modal>
}

export default Rsvp