import React from 'react';
import Fade from 'react-reveal/Fade';

const Venue = (props) => {
    return (
        <div className='venue'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 locationMainDetails'>
                        <Fade bottom>
                            <h2> The Kings Arms Christchurch - 2pm </h2>
                            <p> Our wedding ceremony and reception will be happening at The Kings Arms Christchurch. </p>
                            <h3>Address: 18 Castle St, Christchurch, BH23 1DT </h3>
                        </Fade>
                    </div>
                    <div className='col-md-6 hotelText'>
                        <Fade bottom>
                            <h2>Hotel/Reception</h2>
                            <p>The Wedding breakfast and Reception will be held in the hall which is adjacent to the hotel. (White building in this picture)</p>
                        </Fade>
                    </div>
                    <div className='col-md-6'>
                        <div className={'hotelImage'}></div>
                        <div className={'accentBlock'}></div>
                    </div>
                    <div className='col-md-12 locationSpacer'>
                    </div>
                    <div className='col-md-6'>
                        <div className={'ceremonyImage'}></div>
                        <div className={'accentBlock2'}></div>
                    </div>
                    <div className='col-md-6 ceremonyText'>
                        <Fade bottom>
                            <h2>Wedding Ceremony</h2>

                            <p> The wedding pavilion can be found across the street from the hotel, next to the ruins.
                            This is where the Ceremony will be taking place.</p>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default Venue;