import React, {useState} from 'react';

// type RadioButtonTypes = {
//     bind: {
//         value: string,
//         onChange: (value: string) => void,
//         error: string,
//     },
//     options: {
//         value: string,
//         label: string,
//     }[],
//     label: string,
//     onChange?: (value: string) => void;
// }

function RadioButton(props) {
    // Generates a random id to use for input to stop other field being effected
    const [id] = useState((Math.random() + 1).toString(36).substring(3))
    const selected = props.bind.value;

    return (
        <div>
            <div className={'fieldContainer'}>
                <label>{props.label}</label>

                <div className={'radioButton'}>
                    {props.options.map((option) => (
                        <label key={option.value} className="RadioButtonContainer">{option.label}
                            <input
                                type="radio"
                                name={id}
                                checked={option.value === selected}
                                onChange={() => props.bind.onChange(option.value)}
                            />
                            <span className="checkmark"/>
                        </label>
                    ))}


                </div>

            </div>
            <div className={"errorContainer"}>
                <span className={'errorOffset'}></span>
                {props.bind.error && <p className={'textError'}>{props.bind.error}</p>}
            </div>
        </div>
    );
}

export default RadioButton;