import React, {Fragment, useEffect, useState} from 'react';

import componentLocationToTop from '../../util/componentLocation';
import Rsvp from "../rsvp";


const PageHeader = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = () => {
        const distanceFromTop = componentLocationToTop(this.deerContainer) * -1;
        // animation will only fire when it is viewable
        if (distanceFromTop >= 0) {
            setScrollPosition(distanceFromTop);
        }
    }

    const autoScroll = () => {
        window.scrollBy({
            top: Math.max(document.documentElement.clientHeight, window.innerHeight || 0), // could be negative value
            left: 0,
            behavior: 'smooth'
        });
    }

    return (<Fragment>
            <div className="logoSplitContainer">
                <div className='pageHeading'>
                    <h1 className={'names'}>Sophie & Andrew</h1>
                    <h2 className={'subtitle'}>Invite you to join them</h2>
                    <h1 className={'date'}>27th April 2024</h1>
                    <button onClick={() => setShowModal(true)}>RSVP</button>
                </div>

                {scrollPosition === 0 && <i className="fas fa-angle-double-down" onClick={autoScroll}/>}
            </div>
            <Rsvp showModal={showModal} closeModal={() => setShowModal(false)} />
        </Fragment>

    );
}

export default PageHeader;