import React from 'react';
import PropTypes from 'prop-types';

//TODO: add transparent modal for search, jsut overide the background colour
const Modal = (props) => {
    return (
        <div className={'modalFullPage'} onClick={() => props.close()}>
            <i className={`fas fa-times modalCloseButton`} onClick={() => {props.close()}}/>
            <div className={`modalContainer`} >
                <div className={`modalContent ${props.transparent ? "transparent" : ""}`} onClick={e => e.stopPropagation()}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.any,
    close: PropTypes.func,
    transparent: PropTypes.bool,
};

export default Modal;