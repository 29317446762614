import React from 'react';
import PropTypes from 'prop-types';

export default function Input(props) {
    const errorClass = props.bind.error ? 'has-error' : '';

    const passedClasses = props.className || '';

    return (
        <div>
            <div className={`fieldContainer ${errorClass} ${passedClasses}`}>
                {props.label && <label>{props.label}</label>}


                <input
                    placeholder={props.placeholder}
                    value={props.bind.value}
                    onChange={props.bind.onChange}
                    className="form-control"
                    maxLength={props.maxLength}
                    ref={props.addRef}
                    onKeyDown={props.onKeyDown}
                    type={props.type}
                />

            </div>
            <div className={"errorContainer"}>
                {!props.removeErrorOffset && <span className={'errorOffset'}></span>}
                {props.bind.error && <p className={'textError'}>{props.bind.error}</p>}
            </div>
        </div>
    );
}

Input.propTypes = {
    bind: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        error: PropTypes.string,
        setValue: PropTypes.func
    }).isRequired,
    placeholder: PropTypes.string,
    maxLength: PropTypes.string,
    addRef: PropTypes.any,
    label: PropTypes.string,
    onKeyDown: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
    removeErrorOffset: PropTypes.bool
};