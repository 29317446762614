import React from 'react';
import PageHeader from './pageHeader';
import Venue from './venue';
import WorkExperience from './workExperience';
import '../../style/style.scss';
import Gifts from './gifts';

import Footer from "./footer";
import Rsvp from "./rsvp";

export default () => {
    return (
        <div>
            <PageHeader/>
            <Venue/>
            <WorkExperience/>
            <Gifts/>
            <Footer/>
        </div>
    );
}

