import React from 'react';
import Fade from 'react-reveal/Fade';

const Gifts = (props) => {
    return (
        <div className='gifts'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Fade bottom>
                            <h2> Gifts </h2>
                        </Fade>
                    </div>
                    <div className='col-md-12'>
                        <Fade bottom>
                            <p className={'poem'}>Our life together has already begun,
                                We have almost everything under the sun.
                                If you should wish to buy us a gift,
                                A little extra money would give us a lift.
                                But most importantly, we request,
                                You share our day as our wedding guest.
                                Now that we&#39;ve saved you any fuss,
                                We can&#39;t wait for you to celebrate with us!</p>
                        </Fade>
                    </div>
                </div>
            </div>
            {/*Bottom divider*/}
            <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="55" width="50%" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" className='bottomDivider'>
                <path d="M0 0 L100 100 L100 0 Z" fill="#3c3c3e" stroke="#3c3c3e"/>
            </svg>
            <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="55" width="50%" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" className='bottomDivider2'>
                <path d="M0 0 L100 100 L100 0 Z" fill="#3c3c3e" stroke="#3c3c3e"/>
            </svg>
        </div>
    )
};


export default Gifts;